import { RiRouterFill } from "react-icons/ri";

const Router = (props) => {
  const { rt, showRouterRadius, handleToggleRouterRadius } = props;
  const { size } = rt;

  return (
    <>
      {!!showRouterRadius && (
        <div
          className="router-radius"
          onClick={handleToggleRouterRadius}
          style={{
            position: "absolute",
            width: parseInt(size),
            height: parseInt(size),
            // backgroundColor: "rgb(255, 0, 0, 0.2)",
            top: `calc(${rt.y} - 1% - ${parseInt(size) / 2}px)`,
            left: `calc(${rt.x} - 1% - ${parseInt(size) / 2}px)`,
            borderRadius: "50%",
          }}
        />
      )}
      <RiRouterFill
        onClick={handleToggleRouterRadius}
        size={40}
        color="white"
        style={{ cursor: "pointer", zIndex: 10 }}
      />
    </>
  );
};

export default Router;
