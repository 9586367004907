import { useEffect, useState } from "react";

import Pozzetto from "./components/Pozzetto";
import Button from "./components/Button";
import Router from "./components/Router";

import { AiOutlinePlus, AiFillDelete, AiOutlineCopy } from "react-icons/ai";
import { RiRouterFill } from "react-icons/ri";
import { CgDanger } from "react-icons/cg";
import "./App.css";

import planimetry from "./assets/planimetry.png";

import db from "./db/db.json";

const App = () => {
  const [pozzetti, setPozzetti] = useState([]);
  const [routers, setRouters] = useState([]);
  const [filter, setFilter] = useState("");
  const [addTarget, setAddTarget] = useState(null);
  const [showRouterRadius, setShowRouterRadius] = useState(false);
  const [modX, setModX] = useState(1);
  const [modY, setModY] = useState(1);

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let modX_bak = localStorage.getItem("modX_bak");
    let modY_bak = localStorage.getItem("modY_bak");

    if (!modX_bak) {
      localStorage.setItem("modX_bak", 1);
    } else {
      setModX(modX_bak);
    }

    if (!modY_bak) {
      localStorage.setItem("modY_bak", 1);
    } else {
      setModY(modY_bak);
    }

    const tps = localStorage.getItem("pozzetti");
    const rts = localStorage.getItem("routers");

    let pozs = [];
    if (!!tps) {
      pozs = JSON.parse(tps);
    } else {
      pozs = db.pozzetti;
    }

    setPozzetti(pozs);

    let routs = [];
    if (!!rts) {
      routs = JSON.parse(rts);
    } else {
      routs = db.routers;
    }

    setRouters(routs);
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      const newWidth = window.innerWidth;
      setHeight(newHeight);
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (pozzetti.length) {
      localStorage.setItem("pozzetti", JSON.stringify(pozzetti));
    }
  }, [pozzetti]);

  useEffect(() => {
    if (routers.length) {
      localStorage.setItem("routers", JSON.stringify(routers));
    }
  }, [routers]);

  const handleAdd = (point) => {
    switch (addTarget) {
      case "pozzetti": {
        return handleAddPozzetto(point);
      }
      case "router": {
        return handleAddRouter(point);
      }
      default:
        return;
    }
  };

  const handleAddPozzetto = (point) => {
    const newTouchpoint = {
      id: pozzetti.length + 1,
      x: (point.clientX / width) * 100 - 1 + "%",
      y: (point.clientY / height) * 100 - 1 + "%",
      ethernets: [{ id: 0, text: "" }],
    };

    setPozzetti([...pozzetti, newTouchpoint]);
    setAddTarget(null);
  };

  const handleDeletePozzetto = (idPozzetto, event) => {
    event.stopPropagation();
    setPozzetti(pozzetti.filter((tp) => tp.id !== idPozzetto));
  };

  const handleAddRouter = (point) => {
    const newTouchpoint = {
      id: routers.length + 1,
      x: (point.clientX / width) * 100 - 1 + "%",
      y: (point.clientY / height) * 100 - 1 + "%",
      size: 100,
    };

    setRouters([...routers, newTouchpoint]);
    setAddTarget(null);
  };

  const handleDeleteRouter = (idRouter, event) => {
    event.stopPropagation();
    setRouters(routers.filter((rt) => rt.id !== idRouter));
  };

  const handleToggleRouterRadius = () => {
    setShowRouterRadius(!showRouterRadius);
  };

  const handleUpdateRouter = (idRouter, size) => {
    setRouters(
      routers.map((tp) => {
        if (tp.id === idRouter) {
          return {
            ...tp,
            size,
          };
        }
        return tp;
      })
    );
  };

  const handleAddEthernet = (idPozzetto, event) => {
    event.stopPropagation();
    setPozzetti(
      pozzetti.map((tp) => {
        if (tp.id === idPozzetto) {
          return {
            ...tp,
            ethernets: [
              ...tp.ethernets,
              {
                id: tp.ethernets.length + 1,
                text: "",
              },
            ],
          };
        }
        return tp;
      })
    );
  };

  const handleUpdateEthernet = (idPozzetto, idEthernet, text) => {
    setPozzetti(
      pozzetti.map((tp) => {
        if (tp.id === idPozzetto) {
          return {
            ...tp,
            ethernets: tp.ethernets.map((eth) => {
              if (eth.id === idEthernet) {
                return {
                  ...eth,
                  text,
                };
              }
              return eth;
            }),
          };
        }
        return tp;
      })
    );
  };

  const handleDeleteEthernet = (idPozzetto, idEthernet, event) => {
    event.stopPropagation();
    setPozzetti(
      pozzetti.map((tp) => {
        if (tp.id === idPozzetto) {
          return {
            ...tp,
            ethernets: tp.ethernets.filter((eth) => eth.id !== idEthernet),
          };
        }
        return tp;
      })
    );
  };

  const handleFilter = (ev) => {
    setFilter(ev.target.value);
  };

  const handleAddTarget = (type) => {
    if (type === addTarget) {
      setAddTarget(null);
    } else {
      setAddTarget(type);
    }
  };

  const handleReset = () => {
    const isConfirmed = window.confirm("Sei sicuro di voler procedere?");
    if (isConfirmed) {
      localStorage.setItem("pozzetti_bak", JSON.stringify(pozzetti));
      localStorage.setItem("routers_bak", JSON.stringify(routers));
      localStorage.removeItem("routers");
      localStorage.removeItem("pozzetti");
      setRouters([]);
      setPozzetti([]);
    }
  };

  const handleCopy = async () => {
    return await navigator.clipboard.writeText(
      JSON.stringify({ pozzetti, routers })
    );
  };

  const handleChangeMod = (target, value) => {
    if (target === "x") {
      setModX(value);
      localStorage.setItem("modX_bak", value);
    } else {
      setModY(value);
      localStorage.setItem("modY_bak", value);
    }
  };

  const calcMod = (value, mod) => {
    return `${parseFloat(value) * mod}%`;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <img
        src={planimetry}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
        className="App-logo"
        alt="logo"
      />
      <div
        onClickCapture={handleAdd}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></div>

      {pozzetti.map((tp) => {
        return (
          <div
            style={{
              position: "absolute",
              top: calcMod(tp.y, modY),
              left: calcMod(tp.x, modX),
            }}
          >
            <Pozzetto
              key={`pozzetto_${tp.id}`}
              tp={tp}
              filter={filter}
              handleAddEthernet={handleAddEthernet}
              handleUpdateEthernet={handleUpdateEthernet}
              handleDeletePozzetto={handleDeletePozzetto}
              handleDeleteEthernet={handleDeleteEthernet}
            />
          </div>
        );
      })}

      {routers.map((rt) => {
        return (
          <div
            style={{
              position: "absolute",
              top: calcMod(rt.y, modY),
              left: calcMod(rt.x, modX),
            }}
          >
            <Router
              key={`router_${rt.id}`}
              rt={rt}
              handleDeleteRouter={handleDeleteRouter}
              handleToggleRouterRadius={handleToggleRouterRadius}
              showRouterRadius={showRouterRadius}
            />
          </div>
        );
      })}

      <input
        onChange={handleFilter}
        type="text"
        placeholder="Cerca pozzetto per codice..."
        defaultValue={filter}
        style={{
          position: "absolute",
          top: 16,
          left: 16,
          background: "white",
          padding: "8px 12px",
          borderRadius: 0,
          border: "1px solid #ccc",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 16,
          right: 16,
          background: "white",
          padding: "8px 12px",
          borderRadius: 0,
          gap: 8,
          display: "flex",
        }}
      >
        <Button
          handleOnClick={() => handleAddTarget("pozzetti")}
          icon={<AiOutlinePlus size={14} />}
          label="pozzetto"
          backgroundColor={addTarget === "pozzetti" && "green"}
        />
        <Button
          handleOnClick={() => handleAddTarget("router")}
          icon={<AiOutlinePlus size={14} />}
          label="router"
          backgroundColor={addTarget === "router" && "green"}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 16,
          right: 16,
          background: "white",
          padding: "8px 12px",
          borderRadius: 0,
          gap: 8,
          display: "flex",
        }}
      >
        <Button
          handleOnClick={handleCopy}
          icon={<AiOutlineCopy size={14} />}
          label="copia"
        />
        <Button
          handleOnClick={handleReset}
          icon={<CgDanger size={14} />}
          label="reset"
          backgroundColor="red"
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 16,
          left: 16,
          background: "white",
          padding: "8px 12px",
          borderRadius: 0,
          gap: 8,
          display: "flex",
        }}
      >
        X:
        <input
          type="number"
          placeholder="Mod X"
          value={modX}
          onChange={(ev) => handleChangeMod("x", ev.target.value)}
          style={{
            background: "white",
            padding: "4px 8px",
            borderRadius: 0,
            border: "1px solid #ccc",
            width: 40,
          }}
        />
        Y:
        <input
          type="number"
          placeholder="Mod Y"
          value={modY}
          onChange={(ev) => handleChangeMod("y", ev.target.value)}
          style={{
            background: "white",
            padding: "4px 8px",
            borderRadius: 0,
            border: "1px solid #ccc",
            width: 40,
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 60,
          left: 16,
          background: "white",
          padding: "8px 12px",
          borderRadius: 0,
          border: "1px solid #ccc",
          gap: 8,
          display: "flex",
          flexDirection: "column",
          // width: 100,
        }}
      >
        {showRouterRadius &&
          routers.map((rt) => {
            return (
              <div
                key={`edit_router_${rt.id}`}
                style={{ display: "flex", alignItems: "center", gap: 8 }}
              >
                <RiRouterFill size={16} color="black" />
                <div>Router {rt.id}</div>
                <input
                  placeholder="Dimensione"
                  defaultValue={rt.size}
                  type="number"
                  onChange={(ev) => handleUpdateRouter(rt.id, ev.target.value)}
                  style={{ width: 60, border: "1px solid #ccc" }}
                />
                <Button
                  icon={<AiFillDelete />}
                  handleOnClick={(ev) => handleDeleteRouter(rt.id, ev)}
                />
              </div>
            );
          })}
        <Button
          label={
            showRouterRadius ? "Nascondi info router" : "Mostra info router"
          }
          handleOnClick={handleToggleRouterRadius}
        />
      </div>
    </div>
  );
};

export default App;
