import { useState } from "react";
import { CgEditBlackPoint } from "react-icons/cg";
import { AiFillDelete } from "react-icons/ai";
import { FaEthernet } from "react-icons/fa";
import Button from "./Button";

const Pozzetto = (props) => {
  const {
    tp,
    handleAddEthernet,
    handleUpdateEthernet,
    handleDeletePozzetto,
    handleDeleteEthernet,
    filter,
  } = props;

  const [open, setOpen] = useState(false);

  const isFiltered =
    !!filter && tp.ethernets.some((eth) => eth.text.includes(filter));

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <CgEditBlackPoint
        onClick={handleClick}
        className={isFiltered && "pulse"}
        size={32}
        color="white"
        style={{ cursor: "pointer", zIndex: 0 }}
      />
      {open && (
        <div
          className="pozzetto-tooltip"
          style={{
            zIndex: 1000,
            backgroundColor: "white",
            padding: 16,
            borderRadius: 0,
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: "center",
          }}
        >
          {tp.ethernets.map((eth) => (
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <FaEthernet />
              <input
                key={`ethernet_${eth.id}`}
                onChange={(ev) =>
                  handleUpdateEthernet(tp.id, eth.id, ev.target.value)
                }
                type="text"
                placeholder="Codice"
                defaultValue={eth.text}
                style={{ border: "1px solid #CCC" }}
              />
              <AiFillDelete
                size={18}
                onClick={(ev) => handleDeleteEthernet(tp.id, eth.id, ev)}
                style={{ cursor: "pointer" }}
              />
            </div>
          ))}
          <div style={{ display: "flex", gap: 8, marginTop: 8 }}>
            <Button
              label="aggiungi"
              handleOnClick={(e) => handleAddEthernet(tp.id, e)}
            />
            <Button
              label="elimina"
              backgroundColor="red"
              handleOnClick={(e) => handleDeletePozzetto(tp.id, e)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Pozzetto;
