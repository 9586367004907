const Button = (props) => {
  const { label, handleOnClick, backgroundColor, textColor, icon } = props;
  return (
    <div
      style={{
        backgroundColor: backgroundColor || "#aaa",
        color: textColor || "white",
        cursor: "pointer",
        fontSize: 14,
        padding: "2px 8px",
        borderRadius: 0,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        gap: 4,
      }}
      onClick={handleOnClick}
    >
      {icon}
      {label?.toUpperCase()}
    </div>
  );
};

export default Button;
